import { ProtectedPath } from '@/constants/protectedPath';
import { Routes } from '@/constants/routes';
import type { MenuItem } from '@/types/global';

const appPath = `/${ProtectedPath.APP}`;

export const menuItems: MenuItem[] = [
  {
    translationKey: 'layout.default.enums',
    name: `${ProtectedPath.APP}-enums`,
    child: [
      {
        translationKey: 'layout.default.resources',
        name: `${ProtectedPath.APP}-${Routes.Resources}`,
        to: `${appPath}/${Routes.Resources}`,
      },
      {
        translationKey: 'layout.default.elementaryActivities',
        name: `${ProtectedPath.APP}-${Routes.ElementaryActivities}`,
        to: `${appPath}/${Routes.ElementaryActivities}`,
      },
      {
        translationKey: 'layout.default.compositeActivities',
        name: `${ProtectedPath.APP}-${Routes.CompositeActivities}`,
        to: `${appPath}/${Routes.CompositeActivities}`,
      },
      {
        translationKey: 'layout.default.programs',
        name: `${ProtectedPath.APP}-${Routes.Programs}`,
        to: `${appPath}/${Routes.Programs}`,
      },
      {
        translationKey: 'layout.default.players',
        name: `${ProtectedPath.APP}-${Routes.Players}`,
        to: `${appPath}/${Routes.Players}`,
      },
    ],
  },
  {
    translationKey: 'layout.default.planning',
    name: `${ProtectedPath.APP}-planning`,
    child: [
      {
        translationKey: 'layout.default.calendar',
        name: `${ProtectedPath.APP}-${Routes.Calendar}`,
        to: `${appPath}/${Routes.Calendar}`,
      },
      {
        translationKey: 'layout.default.programPlanning',
        name: `${ProtectedPath.APP}-${Routes.ProgramPlanning}`,
        to: `${appPath}/${Routes.ProgramPlanning}`,
      },
    ],
  },
  {
    translationKey: 'layout.default.settings',
    name: `${ProtectedPath.APP}-settings`,
    child: [
      {
        translationKey: 'layout.default.userSettings',
        name: `${ProtectedPath.APP}-${Routes.UserSettings}`,
        to: `${appPath}/${Routes.UserSettings}`,
      },
      {
        translationKey: 'layout.default.tenantSettings',
        name: `${ProtectedPath.APP}-${Routes.TenantSettings}`,
        to: `${appPath}/${Routes.TenantSettings}`,
      },
    ],
  },
];
